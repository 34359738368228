<template>
  <div v-if="roomInfoLocal" class="chat-room-item d-flex flex-column align-items-center justify-content-between">
		<div class="d-flex flex-column align-items-center" style="width: 100%; height: 100%;">
			<div class="chat-header d-flex align-items-center px-3 py-2" style="width: 100%;"
					v-if="filteredUser(roomInfoLocal.room.users) && filteredUser(roomInfoLocal.room.users)[0] && filteredUser(roomInfoLocal.room.users)[0].user && filteredUser(roomInfoLocal.room.users)[0].user.profile">>
				<img
					:src="'https://api.metav.cl/assets/img/perfiles/' + filteredUser(roomInfoLocal.room.users)[0].user.profile.img_url"
					class="user-avatar mr-2"
				/>
				<div>
					<b>{{filteredUser(roomInfoLocal.room.users)[0].user.profile.nombre}} {{filteredUser(roomInfoLocal.room.users)[0].user.profile.apellido}}</b>
				</div>
			</div>
			<div class="chat-header d-flex align-items-center px-3 py-2" style="width: 100%;"
				v-else>
				<img
					src='https://api.metav.cl/assets/img/perfiles/defecto.png'
					class="user-avatar mr-2"
				/>
				<div class="d-flex flex-column">
					<b>Usuario sin perfil completo</b>
				</div>
			</div>
			<div class="py-2 px-3 chat-bubbles-container" ref="chatContent">
				<div class="chat-bubble-container d-flex" v-for="(message, index) in messages" :key="index"
					:class="message.user_id == $store.state.userData.id_usuario ? 'justify-content-end':''">
					<div class="chat-bubble py-1 px-2 my-1 rounded-lg"
						:class="message.user_id == $store.state.userData.id_usuario ? 'mine':''">
						<p class="mb-0">{{message.message}}</p>
						<p class="mb-0 text-secondary text-right"><small>{{ message.date.toDate() | toDateTime }}</small></p>
					</div>
				</div>
			</div>
			<div class="chat-footer p-2 d-flex align-items-center">
				<CInput
					class="mb-0 mr-1"
					style="width: 100%"
					v-model="chatText"
					:placeholder="$t('enterChatText')"
					:horizontal="{ label: 'col-sm-0', input: 'col-sm-12'}"
					@keypress.enter="sendMessage()"
				/>

				<CButton
					:disabled="chatText == ''"
					@click="sendMessage()"
				>
					<CIcon name="cil-send" />
				</CButton>
			</div>
		</div>
  </div>
  <div v-else>
		<div class="chat-room-item p-5 d-flex flex-column align-items-center justify-content-center text-center">
			<p class="mb-3">{{$t('chatRoomMessageA')}}</p>
			<p class="mb-0"><b>{{$t('chatRoomMessageB')}}</b></p>
		</div>
  </div>
</template>

<script>
import moment from 'moment'
import { getFirestore, onSnapshot, collection, addDoc, doc, query, orderBy, setDoc, increment } from "firebase/firestore"

export default {
  name: 'RoomContent',
  props: {
    roomInfo: {
      type: Object,
      default: null
    }
  },
  filters: {
    toDate: function (date) {
      return moment(date).format('DD-MM-YYYY')
    },
    toDateTime: function (date) {
      return moment(date).format('DD-MM-YYYY HH:mm')
    },
  },
  watch: {
    roomInfo: {
      immediate: true,
      handler (roomInfo) {
				if(roomInfo && roomInfo != this.roomInfoLocal){					
					this.chatSub && this.chatSub()
					this.roomInfoLocal = roomInfo
					this.getMessages()
				}
				else if(!roomInfo){					
					this.chatSub && this.chatSub()
				}
      }
    }
  },
  data () {
    return {
			chatText: '',
			roomInfoLocal: null,
			messages: [],
			chatSub: null
    }
  },
  mounted(){
  },
  methods: {
		filteredUser(users){
			if(users){
				const usersFinal = users.filter((user) => user.user_id != this.$store.state.userData.id_usuario)
				return usersFinal
			}
			return []
		},
		getRoomUsersID(){
			const users = []
			this.roomInfoLocal.room.users.forEach(user => {
				users.push(user.user_id)
			})
			return users
		},
		getMessages(){
			const usersID = this.getRoomUsersID()
			const db = getFirestore()
			const docChats = collection(db, 'chats/' + this.roomInfoLocal.room.chat_room_id + '/messages')
			const queryChats = query(docChats, orderBy('date', 'asc'))
			this.chatSub = onSnapshot(queryChats, async (querySnapshot) => {
				this.messages = []
				querySnapshot.forEach((doc) => {
					this.messages.push(doc.data())
				})

				const db = getFirestore()
				const chatRef = doc(db, 'chats', this.roomInfoLocal.room.chat_room_id)
				const unseen = { [this.$store.state.userData.id_usuario]: 0 }
				await setDoc(chatRef, {
					unseen
				}, { merge: true })

				this.$nextTick(() => {
					this.$refs.chatContent.scrollTop = this.$refs.chatContent.scrollHeight
				})
			})
		},
		async sendMessage(){
			try {
				const usersID = this.getRoomUsersID()
				let unseen = {}
				usersID.filter((userID) => userID != this.$store.state.userData.id_usuario).forEach(userID => {
					unseen[userID] = increment(1)
				})

				const db = getFirestore()
				const chatRef = doc(db, 'chats', this.roomInfoLocal.room.chat_room_id)
				await setDoc(chatRef, {
					lastMessage: this.chatText,
					unseen
				}, { merge: true })

				await addDoc(collection(db, 'chats/' + this.roomInfoLocal.room.chat_room_id + '/messages'), {
					user_id: this.$store.state.userData.id_usuario,
					date: new Date(),
					message: this.chatText,
				});
				
				this.chatText = ''
			} catch (e) {
				console.error("Error adding document: ", e);
			}
		}
	}
}
</script>
<style scoped>
	.chat-room-item{
		height: 100%;
		width: 100%;
		background: rgb(235, 235, 235);
		border-left: 1px solid rgb(206, 206, 206);
	}
	.user-avatar{
		width: 40px;
		height: 40px;
		border-radius: 40px;
	}
	.chat-header{
		background: rgb(247, 247, 247);
		border-bottom: 1px solid rgb(218, 218, 218);
	}
	.chat-footer{
		width: 100%;
		background: rgb(247, 247, 247);
		border-top: 1px solid rgb(218, 218, 218);
	}
	.chat-bubble{
		display: inline-block;
		background: rgb(241, 241, 241);
		border: 1px solid rgb(225, 225, 225);
		max-width: 80%;
	}
	.chat-bubble.mine{
		display: inline-block;
		background: rgb(142, 177, 142);
		border: 1px solid rgb(225, 225, 225);
		color: white;
	}
	.chat-bubbles-container{
		width: 100%;
		height: 100%;
		flex: 1;
		overflow-y: scroll;
	}
</style>