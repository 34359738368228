<template>
  <div>
    <CModal
      :title="$t('editUser')"
      color="primary"
      centered
      :closeOnBackdrop="false"
      size="xl"
      :show.sync="$store.state.editUserModal.status"
      @update:show="changeShowStatus"
    >
      <template #header>
        <h6 class="modal-title">{{$t('editUser')}}</h6>
        <CButtonClose @click="() => {
          $store.commit('setEditUserModal', {status: false, userData: null})
          resetModal()
        }" class="text-white"/>
      </template>
      <CCard>
        <CCardBody>
          <CForm>
            <CRow>
              <CCol sm="12" md="6">
                <CInput
                  v-model="editUser.nombre"
                  :label="$t('name')"
                  :placeholder="$t('enterName')"
                  horizontal
                  :isValid="needValidation ? required:undefined"
                />
                <CInput
                  v-model="editUser.apellido"
                  :label="$t('lastName')"
                  :placeholder="$t('enterLastname')"
                  horizontal
                  :isValid="needValidation ? required:undefined"
                />
                <CSelect
                  :value.sync="editUser.sexo"
                  :label="$t('gender')"
                  horizontal
                  :options="[{label: $t('female'), value: 'Femenino'}, {label: $t('male'), value: 'Masculino'}]"
									:reduce="data => data.value"
                  :placeholder="$t('selectGender')"
                />
                <CInput
                  v-model="editUser.nacimiento"
                  :label="$t('birth')"
                  type="date"
                  :min="setDate(1)"
                  :max="setDate(2)"
                  :description="$t('birthDescription')"
                  horizontal
                  :isValid="needValidation ? required:undefined"
                />
                <CInput
                  v-model="editUser.email"
                  :label="$t('email')"
                  :placeholder="$t('enterEmail')"
                  type="email"
                  horizontal
                  :isValid="needValidation ? required && email:undefined"
                />
              </CCol>
              <CCol>
								<CInput
									v-model="editUser.rut"
									label="Rut"
									description="Rut (Sin puntos ni guión). Ej. 11111111K"
									placeholder="Rut (Sin puntos ni guión)"
									horizontal
									required
								/>
                <CInput
                  v-model="editUser.telefono"
                  :label="$t('phone')"
                  :placeholder="$t('enterPhone')"
                  horizontal
                  :isValid="needValidation ? required:undefined"
                />
                <CInput
                  v-model="editUser.direccion"
                  :label="$t('address')"
                  :placeholder="$t('enterAddress')"
                  horizontal
                  :isValid="needValidation ? required:undefined"
                />
                <div role="group" class="form-group form-row">
                  <label class="col-form-label col-sm-3"> {{$t('country')}} </label>
                  <div class="col-sm-9">
                    <v-select
                      :placeholder="$t('selectCountry')"
                      :options="$store.state.countries"
                      label="name"
                      :clearable="false"
                      :reduce="data => data.name"
                      @option:selected="(country) => {
                        getStates(country.id)
                        editUser.region = null
                        editUser.comuna = null
                      }"
                      required
                      :isValid="needValidation ? required:undefined"
                      v-model="editUser.pais"></v-select>
                  </div>
                </div>
                <div role="group" class="form-group form-row">
                  <label class="col-form-label col-sm-3"> {{$t('region')}} </label>
                  <div class="col-sm-9">
                    <v-select
                      :placeholder="$t('selectRegion')"
                      :options="$store.state.states"
                      label="name"
                      :clearable="false"
                      :reduce="data => data.name"
                      @option:selected="(state) => {
                        getComunas(state.id)
                        editUser.comuna = null
                      }"
                      :isValid="needValidation ? required:undefined"
                      v-model="editUser.region"></v-select>
                  </div>
                </div>
                <div role="group" class="form-group form-row">
                  <label class="col-form-label col-sm-3"> {{$t('comuna')}} </label>
                  <div class="col-sm-9">
                    <v-select
                      :placeholder="$t('selectComuna')"
                      :options="$store.state.comunas"
                      label="name"
                      :clearable="false"
                      :reduce="data => data.name"
                      :isValid="needValidation ? required:undefined"
                      v-model="editUser.comuna"></v-select>
                  </div>
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="12" md="12">
								<h5 class="bg-primary text-white text-center py-2">{{ $t('moveCreditsTitle') }}</h5>
                <CInput
                  v-model="creditosUser"
									type="number"
                  :label="$t('credits')"
                  :placeholder="$t('enterCredits')"
                  :description="$t('changeCreditsDescription')"
                  horizontal
                >
									<template #append>
										<CButton color="primary" size="sm" @click="modifyCredits" :disabled="loading"><CIcon name="cil-check-circle"/> {{$t('modify')}}</CButton>
									</template>
								</CInput>
              </CCol>
            </CRow>
            <CRow class="mb-3">
              <CCol sm="12" md="12">
								<h5 class="bg-primary text-white text-center py-2">{{$t('userDocumentsTitle')}}</h5>
              </CCol>
            </CRow>
            <CRow class="mx-2 my-1 bg-secondary py-2 rounded-lg" alignVertical="center">
              <CCol sm="12" md="4" v-for="(file, index) in editUser.attachments" :key="index" >
								<div class="d-flex flex-row align-items-center">
									<CButtonClose class="mr-2" @click="toggleFromDeleted(file.id)"/>
									<div :style="deletedFiles.includes(file.id) ? 'opacity: 0.3':''">
										<p class="mb-0">{{$t('title')}}: {{file.title}}</p>
										<p class="mb-0">{{$t('description')}}: {{file.description}}</p>
										<p class="mb-0">{{$t('created')}}: {{file.created_at}}</p>
										<p class="mb-0">{{$t('createdBy')}}: {{file.uploader.nombre}} {{file.uploader.apellido}} {{file.uploader.especialidad ? '('+file.uploader.especialidad+')':''}}</p>
										<a :href="file.file_path" target="_blank" class="mb-0"><CIcon name="cil-file"/> {{$t('viewFile')}}</a>
									</div>
								</div>
              </CCol>
            </CRow>
            <CRow class="mb-3">
              <CCol sm="12" md="12">
								<h5 class="bg-primary text-white text-center py-2">{{$t('newUserDocumentsTitle')}}</h5>
              </CCol>
              <CCol sm="12" md="12">
								<CButton block color="primary" @click="onButtonClick" ><CIcon name="cil-check-circle"/> {{$t('addAttachment')}}</CButton>
								<input
									ref="uploader"
									class="d-none"
									type="file"
									accept="image/*,.pdf"
									@change="onFileChanged"
								>
              </CCol>
            </CRow>
            <CRow v-for="(file, index) in files" :key="index" class="mx-2 my-1 bg-secondary py-2 rounded-lg" alignVertical="center">
              <CCol sm="12" md="3">
								<div class="d-flex flex-row align-items-center" >
									<CButtonClose class="mr-2" @click="files.splice(index, 1)"/>
									<p class="mb-0">{{file.name}}</p>
								</div>
              </CCol>
              <CCol sm="12" md="3">
                <CInput
                  v-model="filesInfo[index].title"
                  :label="$t('title')"
                  :placeholder="$t('enterTitle')"
									class="mb-0"
                  :isValid="needValidation ? required:undefined"
                />
              </CCol>
              <CCol sm="12" md="6">
                <CInput
                  v-model="filesInfo[index].description"
                  :label="$t('description')"
                  :placeholder="$t('enterDescription')"
									class="mb-0"
                  :isValid="needValidation ? required:undefined"
                />
              </CCol>
            </CRow>
          </CForm>
        </CCardBody>
      </CCard>
      <div slot="footer" class="d-flex align-items-center justify-end">
        <CBadge v-if="showRequiredAlert" color="warning" class="mx-2 px-2 py-1 text-white"><CIcon name="cil-bell"/> {{$t('allFieldsRequired')}}</CBadge>
        <CButton type="submit" color="primary" @click="sendForm" :disabled="loading"><CIcon name="cil-check-circle" :disabled="loading"/> {{$t('editUser')}}</CButton>
      </div>
    </CModal>
  </div>
</template>

<script>
import {required, email} from '@/helpers/validators'
import {getCountries, getStates, getComunas} from '@/helpers/api/regionalization.js'
import {editUser, modifyCredits} from '@/helpers/api/users.js'
import { clean } from 'rut.js'
import moment from 'moment'

export default {
  name: 'EditUserModal',
  data () {
    return {
      needValidation: false,
      showRequiredAlert: false,
      loading: false,
			files: [],
			filesInfo: [],
			deletedFiles: [],
			creditosUser: 0,
      editUser: {
				id_usuario: null,
        nombre: '',
        apellido: '',
        email: '',
        telefono: '',
        nacimiento: null,
        sexo: 'Femenino',
        img_url: 'defecto.png',
        direccion: '',
        pais: null,
        region: null,
        comuna: null,
				attachments: [],
				rut: ''
      }
    }
  },
  watch: {
    '$store.state.editUserModal': {
      immediate: true,
      handler (editUserModal) {
        if(editUserModal && editUserModal.status && editUserModal.userData){
          this.getUserToEdit()
        }
      }
    }
  },
  mounted(){
    this.getCountries()
  },
  methods: {
    required, email,
		toggleFromDeleted(id){
			console.log("ID: ", id)
			if(this.deletedFiles.includes(id)){
				const found = this.deletedFiles.findIndex((item) => {
					return item == id
				})
				if(found != -1){
					this.deletedFiles.splice(found, 1)
				}
			}
			else{
				this.deletedFiles.push(id)
			}
			console.log("F: ", this.deletedFiles)
		},
    onButtonClick() {
      this.$refs.uploader.click()
    },
    async onFileChanged(e) {
      const newFile = e.target.files[0]
			if(newFile.size < 10000000){
				this.files.push(newFile)
				this.filesInfo.push({
					title: '',
					description: ''
				})
			}
			else{
				this.$store.commit('addToast', {
					color: 'warning',
					header: 'Error',
					message: this.$i18n.t('maxFileSizeAdvice')
				})
				return false
			}
    },
		async getUserToEdit(){
			console.log('this.$store.state.editUserModal.userData: ', this.$store.state.editUserModal.userData)
			Object.keys(this.editUser).forEach(key => {
				this.editUser[key] = this.$store.state.editUserModal.userData[key]
			})
			this.creditosUser = this.$store.state.editUserModal.userData.creditos
			console.log(this.$store.state.editUserModal)
		},
    setDate(type){
      let date = new Date()
      if(type == 1){
        date.setFullYear( date.getFullYear() - 100 )
      }
      else{
        date.setFullYear( date.getFullYear() - 13 )
      }
      return date.toISOString().split("T")[0]
    },
    changeShowStatus(status){
      this.resetModal()
    },
    resetModal(){
      this.needValidation = false
      this.showRequiredAlert = false
			this.files = []
			this.filesInfo = []
			this.loading = false
      this.editUser = {
				id_usuario: null,        
        nombre: '',
        apellido: '',
        email: '',
        telefono: '',
        nacimiento: null,
        sexo: 'Femenino',
        img_url: 'defecto.png',
        direccion: '',
        pais: null,
        region: null,
        comuna: null,
				attachments: [],
				rut: ''
      }
			this.$store.commit('setStates', [])
			this.$store.commit('setComunas', [])
    },
    async getCountries(){
      const countries = await getCountries()
      if(countries.countries){
        this.$store.commit('setCountries', countries.countries)
      }
    },
    async getStates(country_id){
      const states = await getStates({country_id})
      if(states.states){
        this.$store.commit('setStates', states.states)
      }
    },
    async getComunas(state_id){
      const comunas = await getComunas({state_id})
      if(comunas.cities){
        this.$store.commit('setComunas', comunas.cities)
      }
    },
    isValidForm(){
      this.needValidation = true
			const age = moment().diff(moment(this.editUser.nacimiento, 'YYYY-MM-DD'), 'years')
			if(age < 13){
				this.$store.commit('addToast', {
					color: 'warning',
					header: 'Error',
					message: 'La edad debe ser mayor a 13 años'
				})
				return false
			}
      return Object.keys(this.editUser).every((key) => {
				console.log(key + ': ' + this.editUser[key])
				return !!this.editUser[key] || key == 'attachments'
			})
    },
    async sendForm(){
      if(this.isValidForm()){
        this.loading = true
        let completed = false
        const formData = new FormData()
				this.editUser.rut = clean(this.editUser.rut)
        Object.keys(this.editUser).forEach((key) => {
          if(this.editUser[key]){
            formData.append(key, this.editUser[key])
          }
        })
        this.files.forEach((file, index) => {
          if(file && file.size < 10000000){
            formData.append('files[]', file)
          }
        })
				formData.append('files_info', JSON.stringify( this.filesInfo ))
				formData.append('deleted_files', JSON.stringify( this.deletedFiles ))
				formData.append('id_uploader', this.$store.state.userData.id_usuario)
        const user = await editUser(formData)
				console.log(user)
        if(user.data){
          this.$store.dispatch('getClientList', {type: 2})
					this.$store.commit('setEditUserModal', { status: false, userData: null })
					this.resetModal()
					completed = true
        }

        this.$store.commit('addToast', {
          color: completed ? 'success':'warning',
          header: completed ? this.$i18n.t('editedUser'):'Error',
          message: completed ? this.$i18n.t('okEditUser'):this.$i18n.t('errorMessage')
        })
        this.loading = false
      }
      else{
        this.showRequiredAlert = true
      }
    },
		async modifyCredits(){
			let completed = false
			if(this.creditosUser != this.$store.state.editUserModal.userData.creditos){
				const data = {
					user_id: this.$store.state.userData.id_usuario,
					id_usuario: this.$store.state.editUserModal.userData.id_usuario,
					creditos: this.creditosUser,
				}
				const response = await modifyCredits(data)
				completed = true
				this.$store.dispatch('getClientList', {type: 2})
			}
			this.$store.commit('addToast', {
				color: completed ? 'success':'warning',
				header: completed ? this.$i18n.t('okCreditsTitle'):'Error',
				message: completed ? this.$i18n.t('okCredits'):this.$i18n.t('errorMessage')
			})
		}
  }
}
</script>