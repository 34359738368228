import * as firebase from 'firebase/app'

export function initFirebaseService(){
	const firebaseConfig = {
		apiKey: "AIzaSyDEAnc_nfATMrTvLSpWBNogyS58MRKvFeg",
		authDomain: "metav-905df.firebaseapp.com",
		projectId: "metav-905df",
		storageBucket: "metav-905df.appspot.com",
		messagingSenderId: "619646382071",
		appId: "1:619646382071:web:5f1c12f7b148e9999c48f2"
	};

	firebase.initializeApp(firebaseConfig);
}
