<template>
  <div>
    <CModal
      :title="$t('newNetworkUser')"
      color="primary"
      centered
      :closeOnBackdrop="false"
      size="xl"
      :show.sync="$store.state.newNetworkUserModal"
      @update:show="changeShowStatus"
    >
      <template #header>
        <h6 class="modal-title">{{$t('newNetworkUser')}}</h6>
        <CButtonClose @click="() => {
          $store.commit('setNewNetworkUserModal', false)
          resetModal()
        }" class="text-white"/>
      </template>
      <CCard>
        <CCardBody>
          <CForm>
            <CRow>
              <CCol sm="12" md="6">
                <CInput
                  v-model="newUser.nombre"
                  :label="$t('name')"
                  :placeholder="$t('enterName')"
                  horizontal
                  :isValid="needValidation ? required:undefined"
                />
                <CInput
                  v-model="newUser.apellido"
                  :label="$t('lastName')"
                  :placeholder="$t('enterLastname')"
                  horizontal
                  :isValid="needValidation ? required:undefined"
                />
                <CSelect
                  :value.sync="newUser.sexo"
                  :label="$t('gender')"
                  horizontal
                  :options="[{label: $t('female'), value: 'Femenino'}, {label: $t('male'), value: 'Masculino'}]"
									:reduce="data => data.value"
                  :placeholder="$t('selectGender')"
                />
                <CInput
                  v-model="newUser.nacimiento"
                  :label="$t('birth')"
                  type="date"
                  :max="new Date().toISOString().split('T')[0]"
                  :description="$t('birthDescription')"
                  horizontal
                  :isValid="needValidation ? required:undefined"
                />
                <CInput
                  v-model="newUser.email"
                  :label="$t('email')"
                  :placeholder="$t('enterEmail')"
                  type="email"
                  horizontal
                  :isValid="needValidation ? required && email:undefined"
                />
                <CInput
                  v-model="newUser.telefono"
                  :label="$t('phone')"
                  :placeholder="$t('enterPhone')"
                  horizontal
                  :isValid="needValidation ? required:undefined"
                />
              </CCol>
              <CCol>
                <div role="group" class="form-group form-row">
                  <label class="col-form-label col-sm-3"> {{$t('specialty')}} </label>
                  <div class="col-sm-9">
                    <v-select
                      :placeholder="$t('selectSpecialty')"
                      :options="especialties"
                      :reduce="data => data.value"
                      :clearable="false"
                      required
                      :isValid="needValidation ? required:undefined"
                      v-model="newUser.especialidad"></v-select>
                  </div>
                </div>
                <div role="group" class="form-group form-row">
                  <label class="col-form-label col-sm-3"> {{$t('job')}}</label>
                  <div class="col-sm-9">
                    <v-select
                      :placeholder="$t('selectJobType')"
											:options="[{label: $t('contract'), value: 'Dependiente'}, {label: $t('independent'), value: 'Independiente'}]"
											:reduce="data => data.value"
                      :clearable="false"
                      required
                      :isValid="needValidation ? required:undefined"
                      v-model="newUser.trabajo"></v-select>
                  </div>
                </div>
                <CInput
                  v-if="newUser.trabajo == 'Dependiente'"
                  v-model="newUser.empresa"
                  :label="$t('company')"
                  :placeholder="$t('enterCompany')"
                  horizontal
                  :isValid="needValidation ? required:undefined"
                />
                <CInput
                  v-model="newUser.direccion"
                  :label="$t('address')"
                  :placeholder="$t('enterAddress')"
                  horizontal
                  :isValid="needValidation ? required:undefined"
                />
                <div role="group" class="form-group form-row">
                  <label class="col-form-label col-sm-3"> {{$t('country')}} </label>
                  <div class="col-sm-9">
                    <v-select
                      :placeholder="$t('selectCountry')"
                      :options="$store.state.countries"
                      label="name"
                      :clearable="false"
                      :reduce="data => data.name"
                      @option:selected="(country) => {
                        getStates(country.id)
                        newUser.region = null
                        newUser.comuna = null
                      }"
                      required
                      :isValid="needValidation ? required:undefined"
                      v-model="newUser.pais"></v-select>
                  </div>
                </div>
                <div role="group" class="form-group form-row">
                  <label class="col-form-label col-sm-3"> {{$t('region')}} </label>
                  <div class="col-sm-9">
                    <v-select
                      :placeholder="$t('selectRegion')"
                      :options="$store.state.states"
                      label="name"
                      :clearable="false"
                      :reduce="data => data.name"
                      @option:selected="(state) => {
                        getComunas(state.id)
                        newUser.comuna = null
                      }"
                      :isValid="needValidation ? required:undefined"
                      v-model="newUser.region"></v-select>
                  </div>
                </div>
                <div role="group" class="form-group form-row">
                  <label class="col-form-label col-sm-3"> {{$t('comuna')}} </label>
                  <div class="col-sm-9">
                    <v-select
                      :placeholder="$t('selectComuna')"
                      :options="$store.state.comunas"
                      label="name"
                      :clearable="false"
                      :reduce="data => data.name"
                      taggable
                      :createOption="option => ({name:option})"
                      :isValid="needValidation ? required:undefined"
                      v-model="newUser.comuna"></v-select>
                  </div>
                </div>
              </CCol>
            </CRow>
          </CForm>
        </CCardBody>
      </CCard>
      <div slot="footer" class="d-flex align-items-center justify-end">
        <CBadge v-if="showRequiredAlert" color="warning" class="mx-2 px-2 py-1 text-white"><CIcon name="cil-bell"/> {{$t('allFieldsRequired')}}</CBadge>
        <CButton type="submit" color="primary" @click="sendForm" :disabled="loading"><CIcon name="cil-check-circle"/> {{$t('createUser')}}</CButton>
      </div>
    </CModal>
  </div>
</template>

<script>
import {required, email} from '@/helpers/validators'
import {getCountries, getStates, getComunas} from '@/helpers/api/regionalization.js'
import {createUser, createUserProfile} from '@/helpers/api/users.js'

export default {
  name: 'NewNetworkUserModal',
  data () {
    return {
      needValidation: false,
      showRequiredAlert: false,
      loading: false,
      especialties: [
				{label: this.$i18n.t('jobTypeA'), value:'Entrenador'},
				{label: this.$i18n.t('jobTypeB'), value:'Preparador Físico'},
				{label: this.$i18n.t('jobTypeC'), value:'Prof. Educ. Física'},
				{label: this.$i18n.t('jobTypeD'), value:'Kinesiólogo'},
				{label: this.$i18n.t('jobTypeE'), value:'Nutricionista'},
				{label: this.$i18n.t('jobTypeF'), value:'Cirujano Plástico'},
				{label: this.$i18n.t('jobTypeG'), value:'Bariatra'},
				{label: this.$i18n.t('jobTypeH'), value:'Masoterapeuta'},
				{label: this.$i18n.t('jobTypeI'), value:'Masajista'},
				{label: this.$i18n.t('jobTypeJ'), value: 'Médico General'},
				{label: this.$i18n.t('jobTypeK'), value: 'Nutriólogo'},
				{label: this.$i18n.t('jobTypeL'), value: 'Cardiólogo'},
				{label: this.$i18n.t('jobTypeM'), value: 'Diabetólogo'},
				{label: this.$i18n.t('jobTypeN'), value: 'Psicólogo'},
				{label: this.$i18n.t('jobTypeO'), value: 'Otra especialidad médica'},
			],
      newUser: {
        nombre: '',
        apellido: '',
        email: '',
        telefono: '',
        nacimiento: null,
        sexo: 'Femenino',
        img_url: 'defecto.png',
        direccion: '',
        pais: null,
        region: null,
        comuna: null,
        especialidad: null,
        trabajo: null,
        empresa: null
      }
    }
  },
  mounted(){
    this.getCountries()
  },
  methods: {
    required, email,
    setDate(type){
      let date = new Date()
      if(type == 1){
        date.setFullYear( date.getFullYear() - 100 )
      }
      else{
        date.setFullYear( date.getFullYear() - 13 )
      }
      return date.toISOString().split("T")[0]
    },
    changeShowStatus(status){
      this.resetModal()
    },
    resetModal(){
      this.needValidation = false
      this.showRequiredAlert = false
      this.loading = false
      this.newUser = {
        nombre: '',
        apellido: '',
        email: '',
        telefono: '',
        nacimiento: null,
        sexo: 'Femenino',
        img_url: 'defecto.png',
        direccion: '',
        pais: null,
        region: null,
        comuna: null,
        especialidad: null,
        trabajo: null,
        empresa: null,
      }
    },
    async getCountries(){
      const countries = await getCountries()
      if(countries.countries){
        this.$store.commit('setCountries', countries.countries)
      }
    },
    async getStates(country_id){
      const states = await getStates({country_id})
      if(states.states){
        this.$store.commit('setStates', states.states)
      }
    },
    async getComunas(state_id){
      const comunas = await getComunas({state_id})
      if(comunas.cities){
        this.$store.commit('setComunas', comunas.cities)
      }
    },
    isValidForm(){
      this.needValidation = true
      return Object.keys(this.newUser).every((key) => {
        return !!this.newUser[key] || ( this.newUser.trabajo == 'Independiente' && key == 'empresa' )
      })
    },
    async sendForm(){
      if(this.isValidForm()){
        this.loading = true
        let completed = false
				try{
					let userData = {...this.newUser, ...{
            lang: this.$i18n.locale,
            parent_user_id: this.$store.state.userData.id_usuario,
            type: 1,
          }}
          const user = await createUser(userData)
					if(user.data){
						completed = true
						this.$store.dispatch('getUsersList', {type: 1})
						this.$store.commit('setNewNetworkUserModal', false)
						this.resetModal()
					}
				}
				catch(e){
					completed = false
				}

        this.$store.commit('addToast', {
          color: completed ? 'success':'warning',
            header: completed ? this.$i18n.t('createdUser'):'Error',
            message: completed ? this.$i18n.t('okCreatedUser'):this.$i18n.t('errorCreatedUser')
        })
        this.loading = false
      }
      else{
        this.showRequiredAlert = true
      }
    }
  }
}
</script>