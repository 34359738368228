import moment from 'moment'

export function required(data){
	return !!data
}
export function email(email){
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
export function minDate(date){
	return moment().diff(moment(date, 'YYYY-MM-DD'), 'months') > 156 && moment().diff(moment(date, 'YYYY-MM-DD'), 'months') <= 1200
}