<template>
	<div>
		<ModalChatContainer />
		<div class="chat-container">
			<CBadge class="chat-badge-number" shape="pill" color="danger">{{totalMessages}}</CBadge>
			<CButton size="sm" color="primary" class="chat-bubble-button" @click="$store.commit('setModalChatStatus', true)">
				<CIcon name="cil-chat-bubble" class="chat-bubble" size="lg"/>
			</CButton>
		</div>
	</div>
</template>
<script>
import { getFirestore, onSnapshot, collection, addDoc, doc, query, orderBy, setDoc } from "firebase/firestore"
import ModalChatContainer from '@/views/chat/ModalChat/ModalChatContainer'
export default {
    name: 'ChatIndex',
		components: {ModalChatContainer},
		watch: {
			'$store.state.chatRooms': {
				immediate: true,
				handler (chatRooms) {
					if(chatRooms && chatRooms != this.chatRoomsLocal ){
						this.chatRoomsLocal = chatRooms
						this.setListeners()
					}
				}
			}
		},
		data(){
			return {
				chatRoomsLocal: [],
				roomsInfo: {},
				totalMessages: 0
			}
		},
		methods: {
			playNotification(){
				var audio = new Audio(require('@/assets/sounds/newmessage.mp3'))
				audio.play()
			},
			setListeners(){
				const db = getFirestore()
				this.chatRoomsLocal.forEach(room => {
					const docChats = doc(db, 'chats/' + room.room.chat_room_id)
					this.chatSub = onSnapshot(docChats, (doc) => {
						const roomData = doc.data()
						if(doc.exists && roomData){
							this.roomsInfo[room.room.chat_room_id] = roomData
							this.$store.commit('setChatRoomsInfo', this.roomsInfo)
							this.countMyMessagesTotal()
						}
					})
				})
			},
			countMyMessagesTotal(){
				let totalMessages = 0
				Object.values(this.roomsInfo).forEach(room => {
					if(room && room.unseen && room.unseen[this.$store.state.userData.id_usuario] ){
						totalMessages = totalMessages + room.unseen[this.$store.state.userData.id_usuario]
						this.playNotification()
					}
				})
				this.totalMessages = totalMessages
			},
		}
}
</script>

<style scoped>
	.chat-container{
		position: fixed;
		bottom: 10px;
		right: 10px;
	}
	.chat-bubble-button{
		width: 50px;
		height: 50px;;
	}
	.chat-badge-number{
		position: absolute;
		top: 0;
		right: 0;
	}
</style>