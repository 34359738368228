<template>
  <div>
    <CModal
      :title="$t('modalChatTitle')"
      color="primary"
      centered
      :closeOnBackdrop="false"
      size="xl"
			addContentClasses="chat-modal-content"
      :show.sync="$store.state.modalChatStatus"
      @update:show="changeShowStatus"
    >
      <template #header>
        <h6 class="modal-title">{{$t('modalChatTitle')}}</h6>
        <CButtonClose @click="() => {
          $store.commit('setModalChatStatus', false)
          resetModal()
        }" class="text-white"/>
      </template>
			<div class="chat-modal-body">
				<CRow class="no-gutters" style="height: 100%;">
					<CCol xs="12" lg="4" style="height: 100%;">
						<CListGroup class="list-room-container" v-if="$store.state.chatRooms.length > 0">
							<CListGroupItem v-for="(room, index) in $store.state.chatRooms" :key="index"
								class="list-room-item d-flex flex-row align-items-center justify-content-between py-1"
								:class="room.chat_room_id == $store.state.selectedChatRoom ? 'selected':''"
								@click="setActiveRoom(room)">
								<div class="d-flex flex-row align-items-center"
									v-if="filteredUser(room.room.users) && filteredUser(room.room.users)[0] && filteredUser(room.room.users)[0].user && filteredUser(room.room.users)[0].user.profile">
									<img
										:src="'https://api.metav.cl/assets/img/perfiles/' + filteredUser(room.room.users)[0].user.profile.img_url"
										class="user-avatar mr-2"
									/>
									<div class="d-flex flex-column">
										<b>{{filteredUser(room.room.users)[0].user.profile.nombre}} {{filteredUser(room.room.users)[0].user.profile.apellido}}</b>
										<small>{{getRooomLastMessage(room.room.chat_room_id)}}</small>
									</div>
								</div>
								<div class="d-flex flex-row align-items-center"
									v-else>
									<img
										src='https://api.metav.cl/assets/img/perfiles/defecto.png'
										class="user-avatar mr-2"
									/>
									<div class="d-flex flex-column">
										<b>Usuario sin perfil completo</b>
										<small>{{getRooomLastMessage(room.room.chat_room_id)}}</small>
									</div>
								</div>
								<CBadge v-if="getRooomCount(room.room.chat_room_id)" class="chat-badge-number" shape="pill" color="danger">{{getRooomCount(room.room.chat_room_id)}}</CBadge>
							</CListGroupItem>
						</CListGroup>
						<div class="chat-room-item p-5 d-flex flex-column align-items-center justify-content-center text-center" style="height: 100%;">
							<p class="mb-3">{{$t('noChatRoomMessageA')}}</p>
							<p class="mb-3">{{$t('noChatRoomMessageB')}}</p>
							<CButton size="sm" color="success" class="mx-1">
								<CIcon name="cil-chat-bubble" size="sm"/>
							</CButton>
						</div>
					</CCol>
					<CCol xs="12" lg="8" style="height: 100%;">
						<RoomContent :room="$store.state.selectedChatRoom" :roomInfo="$store.state.selectedRoomInfo" style="height: 100%;"/>
					</CCol>
				</CRow>
			</div>
      <div slot="footer-wrapper" class="d-flex align-items-center justify-end">
      </div>
    </CModal>
  </div>
</template>

<script>
import RoomContent from './RoomContent'
export default {
  name: 'ChatContainerModal',
	components: {RoomContent},
  data () {
    return {
			chatText: '',
    }
  },
  mounted(){
  },
  methods: {
		setActiveRoom(room){
			this.$store.commit('setSelectedRoom', room)
		},
		getRooomCount(chat_room_id){
			if(this.$store.state.chatRoomsInfo[chat_room_id] && this.$store.state.chatRoomsInfo[chat_room_id].unseen){
				return this.$store.state.chatRoomsInfo[chat_room_id].unseen[this.$store.state.userData.id_usuario]
			}
			return false
		},
		getRooomLastMessage(chat_room_id){
			if(this.$store.state.chatRoomsInfo[chat_room_id]){
				return this.$store.state.chatRoomsInfo[chat_room_id].lastMessage
			}
		},
		resetModal(){
			this.chatText = ''
		},
    changeShowStatus(status){
      this.resetModal()
    },
		filteredUser(users){
			if(users){
				const usersFinal = users.filter((user) => user.user_id != this.$store.state.userData.id_usuario)
				return usersFinal
			}
			return []
		}
	}
}
</script>
<style scoped>
	/deep/ .chat-modal-content .modal-body{
		padding: 0;
	}
	.chat-modal-body{
		height: 70vh;
		max-height: 70vh;
	}
	.user-avatar{
		width: 40px;
		height: 40px;
		border-radius: 40px;
	}
	.list-room-container{
		border-radius: 0;
		height: 100%;
		background: rgb(219, 219, 219);
		overflow-y: scroll;
	}
	.list-room-item{
		cursor: pointer;
		border: 0;
		background: transparent;
		border-bottom: 1px solid rgb(206, 206, 206);
	}
	.list-room-item.selected{
		background: rgb(235, 235, 235)!important;
	}
</style>