<template>
    <div>
        <NewUserModal />
        <EditUserModal />
        <NewNetworkUserModal />
        <EditNetworkUserModal />
        <NewEvaluation />
				<CambiaPassModal />
    </div>
</template>
<script>
import NewUserModal from './Clients/NewUserModal.vue'
import EditUserModal from './Clients/EditUserModal.vue'
import NewNetworkUserModal from './Red/NewNetworkUserModal.vue'
import EditNetworkUserModal from './Red/EditNetworkUserModal.vue'
import NewEvaluation from './Evaluations/NewEvaluation'

import CambiaPassModal from './General/CambiaPassModal'

export default {
    name: 'ModalsIndex',
    components: {NewUserModal, EditUserModal, NewNetworkUserModal, EditNetworkUserModal, NewEvaluation, CambiaPassModal}
}
</script>