import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import {getMyClients, getMyEvaluators, getEvaluationUsers} from '@/helpers/api/users.js'
import {getUserChatRooms} from '@/helpers/api/user.js'
import {listEvaluations} from '@/helpers/api/evaluations.js'

const token = localStorage.getItem('userToken')

const state = {
	user: null,
  userData: null,
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  isAuthenticated: token,
  loadingClientList: false,
  clientList: null,
	clientListTotal: 0,
	loadingEvaluationClientList: false,
	evaluationClientList: [],
  newUserModal: false,
  editUserModal: {status: false, userData: null},
  loadingEvaluatorList: false,
  evaluatorList: null,
  newNetworkUserModal: false,
  editNetworkUserModal: {status: false, userData: null},
  countries: [],
  states: [],
  comunas: [],
  companies: [{name: 'Provida', id: 9}],
  fixedToasts: [],
  evaluationsList: null,
  evaluationsListTotal: 0,
	evaluationsListShowCompany: false,
  loadingEvaluationsList: false,
  newEvaluationModal: false,
	dashboardData: null,
	modalChatStatus: false,
	chatRooms: [],
	chatRoomsInfo: [],
	selectedChatRoom: null,
	selectedRoomInfo: null,
	changePasswordModal: false,
}

const mutations = {
	setUser(state, user){
		state.user = user
	},
  setUserData (state, user) {
		console.log('datos: ', user)
		const data = {
			...user.datos,
			pais: user.pais.name,
			account_level: user.account_level
		}
    state.userData = data
  },
  setIsAuthenticated (state, status) {
    state.isAuthenticated = status
  },
  setLoadingEvaluationClientList (state, status) {
    state.loadingEvaluationClientList = status
  },
  setEvaluationClientList (state, list){
    state.evaluationClientList = list
  },
  setLoadingClientList (state, status) {
    state.loadingClientList = status
  },
  setClientList (state, list){
    state.clientList = list
  },
  setClientListTotal (state, total){
    state.clientListTotal = total
  },
  setNewUserModal(state, status){
    state.newUserModal = status
  },
  setEditUserModal(state, data){
    state.editUserModal = data
  },
  setLoadingEvaluatorList (state, status) {
    state.loadingEvaluatorList = status
  },
  setEvaluatorList (state, list){
    state.evaluatorList = list
  },
  setNewNetworkUserModal(state, status){
    state.newNetworkUserModal = status
  },
  setEditNetworkUserModal(state, data){
    state.editNetworkUserModal = data
  },
  setCountries(state, countries){
    state.countries = countries
  },
  setStates(state, states){
    state.states = states
  },
  setComunas(state, comunas){
    state.comunas = comunas
  },
  setCompanies(state, companies){
    state.companies = companies
  },
  setEvaluations(state, evaluations){
    state.evaluationsList = evaluations.datos
    state.evaluationsListShowCompany = evaluations.show_company
    state.evaluationsListTotal = evaluations.datos_total
  },
  setLoadingEvaluationsList(state, status){
    state.loadingEvaluationsList = status
  },
  setNewEvaluationModal(state, status){
    state.newEvaluationModal = status
  },
  addToast(state, newToast){
    state.fixedToasts.push(newToast)
  },
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  setDashboardData(state, data){
		state.dashboardData = data
	},
  setModalChatStatus(state, status){
		state.modalChatStatus = status
	},
  setChatRooms(state, data){
		state.chatRooms = data
	},
	setChatRoomsInfo(state, data){
		state.chatRoomsInfo = data
	},
  setSelectedRoom(state, data){
		state.selectedChatRoom =  data.chat_room_id
		state.selectedRoomInfo =  data
	},
	setChangePasswordModalSatus(state, status){
		state.changePasswordModal = status
	},
}
const actions = {
  async getUserChatRoomsList(context, query){
    if(context.state.userData && context.state.userData.id_usuario){
      const data = {
        user_id: context.state.userData.id_usuario
      }
      const rooms = await getUserChatRooms(data)
			context.commit('setChatRooms', rooms.user_rooms)
    }
  },
  async getClientList(context, query){
    if(context.state.userData && context.state.userData.id_usuario){
      context.commit('setLoadingClientList', true)

      const data = {...{
        type: 2,
        parent_user_id: context.state.userData.id_usuario,
				limit: query.limit,
				skip: query.skip
			},
			...query.filters
      }
      const users = await getMyClients(data)
      context.commit('setClientList', users.clients)
      context.commit('setClientListTotal', users.total)
      context.commit('setLoadingClientList', false)
    }
  },
  async getEvaluatorsList(context, query){
    if(context.state.userData && context.state.userData.id_usuario){
      context.commit('setLoadingEvaluatorList', true)

      const data = {
        parent_user_id: context.state.userData.id_usuario
      }
      const users = await getMyEvaluators(data)
      context.commit('setEvaluatorList', users.evaluators)
      context.commit('setLoadingEvaluatorList', false)
    }
  },
  async getEvaluationUsersList(context, query){
    if(context.state.userData && context.state.userData.id_usuario){
      context.commit('setLoadingEvaluationClientList', true)

      const data = {
        type: query.type,
				search: query.search,
        parent_user_id: context.state.userData.id_usuario
      }
      const users = await getEvaluationUsers(data)
      context.commit('setEvaluationClientList', users.lista)
      context.commit('setLoadingEvaluationClientList', false)
    }
  },
  async getEvaluationList(context, query){
    if(context.state.userData && context.state.userData.id_usuario  && !context.state.loadingEvaluationsList){
      context.commit('setLoadingEvaluationsList', true)

      const data = {
        type: context.state.user.type,
				page: query.page,
				limit: query.limit,
				filter_nombre: query.filters.nombre,
				filter_apellido: query.filters.apellido,
				filter_email: query.filters.email,
				filter_rut: query.filters.rut,
				filter_company: query.filters.company,
        id_usuario: context.state.userData.id_usuario
      }
      const evaluations = await listEvaluations(data)
      context.commit('setEvaluations', evaluations)
      context.commit('setLoadingEvaluationsList', false)
    }
  }
}

export default new Vuex.Store({
  state,
  mutations,
  actions
})