import 'core-js/stable'
import Vue from 'vue'
import "babel-core/register"
import "babel-polyfill"
import "core-js/stable"
import "regenerator-runtime/runtime"
import vSelect from 'vue-select'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import {i18n} from '@/helpers/langs/i18n'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import {initFirebaseService} from '@/helpers/firebase/index'

initFirebaseService()

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)
Vue.component('v-select', vSelect)


new Vue({
  el: '#app',
  router,
  store,
  icons,
	i18n,
  template: '<App/>',
  components: {
    App
  }
})
