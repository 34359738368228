import {endpoints} from './endpoints'
import request from '@/helpers/request'
import store from '@/store.js'

export function getMyClients(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getMyClients,
      method: 'get',
      params: data
    })
		  .then(async function (response) {
        resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function getMyEvaluators(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getMyEvaluators,
      method: 'get',
      params: data
    })
		  .then(async function (response) {
        resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function getUsers(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getUsers,
      method: 'get',
      params: data
    })
		  .then(async function (response) {
        resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function getEvaluationUsers(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getEvaluationUsers,
      method: 'get',
      params: data
    })
		  .then(async function (response) {
        resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function createUser(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.createUserAccount,
      method: 'post',
      params: data
    })
		  .then(async function (response) {
        resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function createUserProfile(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.createUserProfile,
      method: 'post',
      params: data
    })
		  .then(async function (response) {
        resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}

export function getDetails(userID){
	return new Promise((resolve, reject) => {
		const data = {
	    id: userID
	  }
    request({
      url: endpoints.userDetails,
      method: 'get',
      params: data
    })
		  .then(function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    resolve(error)
		  });
	})
}

export function editUser(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.editUser,
      method: 'post',
      data
    })
		  .then(function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    resolve(error)
		  });
	})
}

export function deleteUser(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.deleteUser,
      method: 'post',
      data
    })
		  .then(function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    resolve(error)
		  });
	})
}

export function modifyCredits(data){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.modifyCredits,
      method: 'post',
      data
    })
		  .then(function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    resolve(error)
		  });
	})
}