const endpoints = {
    login: '/web/authenticate',
    deleteUser: '/web/deleteUser',
    authUser: '/web/authenticate/user',
    user: '/web/datosusuario',
    getUsers: '/web/listadoperfil',
    getMyClients: '/web/getMyClients',
    getMyEvaluators: '/web/getMyEvaluators',
		getEvaluationUsers: '/web/evaluationListing',
    getUserChatRooms: '/web/getUserChatRooms',
    getOrCreateUserChatRoom: '/web/getOrCreateUserChatRoom',
    getCountries: '/web/countries',
    getStates: '/web/states',
    getComunas: '/web/cities',
    createUserAccount: '/web/creacuenta',
    createUserProfile: '/web/ingresoperfil',
    editUser: '/web/editaperfil',
    deleteUser: '/web/eliminausuario',
    listEvaluations: '/web/listaevaluaciones',
    getEvaluation: '/web/getevaluacion',
		deleteEvaluation: '/web/eliminaevaluacion',
    sendEvaluationByEmail: '/web/enviarevalua',
    createEvaluation: '/web/ingresaevaluacion',
    editEvaluation: '/web/editEvaluation',
		dashboard: '/web/getdash',
		modifyCredits: '/web/cambiacreditosnew',
		actualizapass: '/web/actualizapass',
		reestablecepass: '/web/reestablecepass',
		createUser: '/web/create_user',
		getCompanies: '/web/getCompanies'
}

export {endpoints}