<template>
  <div>
    <CModal
      :title="$t('changePassword')"
      color="primary"
      centered
      :closeOnBackdrop="false"
      size="sm"
      :show.sync="$store.state.changePasswordModal"
      @update:show="changeShowStatus"
    >
			<p>Por favor cambia tu contraseña</p>
			<CInput
				:placeholder="$t('password')"
				type="password"
				autocomplete="curent-password"
				v-model='password'
			>
				<template #prepend-content><CIcon name="cil-lock-locked"/></template>
			</CInput>
			<CInput
				:placeholder="$t('confirmPassword')"
				type="password"
				autocomplete="curent-password"
				v-model='confirm_password'
			>
				<template #prepend-content><CIcon name="cil-lock-locked"/></template>
			</CInput>
			<CButton block color="primary" class="px-4" @click.stop="startChangePass">Modificar</CButton>
      <div slot="footer-wrapper" class="d-flex align-items-center justify-end">
      </div>
		</CModal>
	</div>
</template>

<script>
import {actualizapass} from  '@/helpers/api/user.js'

export default {
	name: 'CambiaPassModal',
	data(){
		return{
			password: null,
			confirm_password: null,
		}
	},
	methods: {
		startChangePass(){
			if(this.password == this.confirm_password){
				this.changePass()		
			}
			else{
				this.$store.commit('addToast', {
					color: 'warning',
					header: 'Error',
					message: this.$i18n.t('passwordDoNotMatch')
				})
			}
		},
		async changePass(){
			const data = {
				id_usuario: this.$store.state.userData.id_usuario,
				password: this.password
			}
			let completed = false
			try{
				await actualizapass(data)
				completed = true
				this.$store.commit('setChangePasswordModalSatus', false)
			}
			catch(e){
				completed = false
			}

			this.$store.commit('addToast', {
				color: completed ? 'success':'warning',
				header: completed ? this.$i18n.t('okChangePassTitle'):'Error',
				message: completed ? this.$i18n.t('okChangePass'):this.$i18n.t('errorMessage')
			})
		},
    changeShowStatus(status){
      this.resetModal()
    },
		resetModal(){
			this.password = null
			this.confirm_password = null
		}
	}
}
</script>
